function update_navbar() {
    let navbar_replacement = document.getElementById("navbar-replacement")
    let navigation = document.getElementById("navigation")

    if (document.body.getBoundingClientRect().width > 1000) {
        navbar_replacement.style.display = "none"
        navigation.style.display = "block"
    } else {
        navigation.style.display = "none"
        navbar_replacement.style.display = "block"
    }
}

window.addEventListener("resize", update_navbar)

// window.addEventListener("load", update_navbar)
update_navbar()
